import HeaderSub from "../../components/header_sub";
import banner3 from "../../assets/banner_3.jpg";
import banner3Webp from "../../assets/banner_3.webp";
import MenuTop from "../../components/menu_top";
import EncartLeft from "../../components/encart_left_2";
import FooterSite from "../../components/footerSite";
import {Link, useNavigate} from "react-router-dom";
import {useEffect} from "react";

const Step3 = ({ room, setActualStep, offer }) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('application_'+room.id) === null) {
            navigate(`/room/${room.id}/form`);
        }
    }, [])

    const firstname = localStorage.getItem('application_'+room.id) ? JSON.parse(localStorage.getItem('application_'+room.id)).firstname : '';

    return (
        <>
            <HeaderSub bannerWebp={banner3Webp} banner={banner3}/>

            <MenuTop setActualStep={setActualStep} actualMenu={3} />

            <div className="mx-auto max-w-7xl mb-8">
                <div className="flex flex-col md:flex-row items-start gap-4">
                    <EncartLeft room={room} setActualStep={setActualStep} offer={offer} />

                    <div className="bg-white w-5/5 md:w-3/5 p-8">
                        <p>
                            <Link
                                to={`/room/${room.id}/form`}
                                state={{ actualStep: 2 }}
                            >
                            <button className="font-bold text-lg text-gray-500">
                                {"< Retour"}
                            </button>
                            </Link>
                        </p>
                        <h3 className="text-2xl	font-bold my-8">Bienvenue {firstname}</h3>
                        <p className="mb-4">
                            Vous n’êtes qu’à un pas de démarrer votre interview spontanée.<br /><br />
                            On vous rappelle le principe :<br /><br />
                            Nous allons vous poser {room.number_question} questions. Pour chacune d’entre elles vous aurez {room.time_question} secondes
                            pour y réfléchir et {room.time_response} autres secondes pour y répondre.<br /><br />

                            Avant de commencer n’oubliez pas d’activer l’accès au micro et à la caméra.
                        </p>
                        <Link
                            to={`/room/${room.id}/test`}
                            state={{ actualStep: 4 }}
                        >
                            <button
                                className="w-full md:w-auto mt-16 mb-64 px-3 py-2 md:px-32 text-lg font-semibold shadow-sm bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme border border-white hover:border-pink-whyme cursor-pointer"
                            >
                                Je débute le test
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            <FooterSite room={room} />
        </>
    )
}

export default Step3;
