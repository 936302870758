import axios from "axios";
import {useEffect, useState} from "react";
import HeaderSub from "./header_sub";
import FooterSite from "./footerSite";
import banner3Webp from "../assets/banner_3.webp";
import banner3 from "../assets/banner_3.jpg";
import FinalEncart from "./final_encart";
import {Link} from "react-router-dom";

const FinalStep = ({ room, blobs, setActualStep, offer }) => {
    const [videoFile, setVideoFile] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const sendRequests = async() => {
            const data = JSON.parse(localStorage.getItem('application_' + room.id));

            const countBlobs = blobs.length ;
            const formDataVideo = new FormData();
            formDataVideo.append('id_app', data.id);
            formDataVideo.append('count_blobs', countBlobs);

            const generateVideo = await axios.post(
                process.env.REACT_APP_BASE_URL + 'api/application/' + room.id + '/generate',
                formDataVideo,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                });

            setVideoFile(process.env.REACT_APP_BASE_URL + generateVideo.data.final_file);

            // une fois tous les blobs envoyés, on envoi les infos du formulaire et on demande la vidéo en retour
            // @todo add loader page final
            setLoading(false)
        }

        submitForm()
            .catch(console.error);

        sendRequests()
            .catch(console.error);
    }, []);

    const submitForm = async () => {
        const data = JSON.parse(localStorage.getItem('application_'+room.id));

        const formData = new FormData();
        if (typeof data.file !== 'string') {
            formData.append("file", data.file);
        }
        formData.append("id", data.id);
        formData.append("firstname", data.firstname);
        formData.append("lastname", data.lastname);
        formData.append("email", data.email);
        formData.append("phone", data.phone);
        formData.append("linkedin", data.linkedin);
        formData.append("video", "blob");
        formData.append("mobile", 0);

        try {
            await axios.post(
                process.env.REACT_APP_BASE_URL + 'api/application/' + room.id,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                });
        } catch (err) {
            console.error(err);
        }

        localStorage.removeItem('application_'+room.id);
        localStorage.removeItem('application_offer_'+room.id);
        return false;
    };

    let firstnameDisplay = localStorage.getItem('firstname_'+room.id);
    firstnameDisplay = firstnameDisplay.charAt(0).toUpperCase() + firstnameDisplay.slice(1);

    return (
        <>
            <HeaderSub bannerWebp={banner3Webp} banner={banner3}/>

            <div className="mx-auto max-w-7xl mb-8 mt-4">
                <div className="flex flex-col md:flex-row items-start gap-4">
                    <FinalEncart room={room} setActualStep={setActualStep} load={loading} videoFile={videoFile} offer={offer} />

                    <div className="bg-white w-5/5 md:w-3/5 p-8">
                        <h3 className="text-2xl	font-bold my-8">Félicitations {firstnameDisplay}!</h3>
                        <p className="mb-4">
                            Votre candidature a bien été envoyée avec votre interview spontanée et vous venez de recevoir un mail de confirmation.<br /><br />
                            Vous pouvez consulter votre interview en cliquant sur la vidéo juste à gauche.<br /><br />
                            Nous espérons que vous avez appréciez cette expérience et si vous souhaitez nous faire des remarques, vous pouvez vous exprimer par mail à < a href="mailto:hello@whyme.jobs">hello@whyme.jobs</a><br /><br />
                            L’équipe WhyMe.
                        </p>
                        <Link
                            to={`/room/${room.id}`}
                            state={{ actualStep: 1 }}
                        >
                            <button
                                className="w-full md:w-auto mt-16 mb-64 px-3 py-2 md:px-32 text-lg font-semibold shadow-sm bg-pink-whyme hover:bg-white text-white hover:text-pink-whyme border border-white hover:border-pink-whyme cursor-pointer"
                            >
                                Retourner à l’annonce
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            <FooterSite room={room} />
        </>
    );
}

export default FinalStep;
